import React from 'react';
import './App.css';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';

import Header from './components/Header';
import Home from './pages/Home';
import About from './pages/About';
import Explore from './pages/Explore'
import SitePage from "./pages/Site";

function App() {
  return (
    <div className="h-screen bg-gray-50">
      <div className="mx-auto mb-12">
        <Router>
          <Header />
          <Routes>
            <Route exact path='/' element={< Home />} />
            <Route exact path='/sites' element={< Explore />} />
            <Route path='/site/:id' element={< SitePage />} />
            <Route exact path='/about' element={< About />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
