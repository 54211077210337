import React from 'react';
import {ReactComponent as LoadIcon} from '../assets/icons/hero-loader.svg';

function LoadingIcon() {

  return (
    <div className="relative w-32">
      <LoadIcon className="w-32 h-32 static animate-spin" />
      <div className="text-center pt-6 bold animte-pulse">Loading ... </div>
    </div>
  )
}

export default LoadingIcon;