import React, { useState, useEffect } from 'react';
import Post from '../components/Post';
import { useParams } from 'react-router-dom';
import { getSites, getPosts } from "../data/fetchers";
import LoadingIcon from '../components/LoadingIcon';

function SitePage() {

  const { id } = useParams();
  const [posts, setPosts] = useState([]);
  const [site, setSite] = useState({});

  const setSitesWrapper = (sites = []) => {
    if (sites.length > 0) {
      setSite(sites[0])
    } else {
      setSite({})
    }
  };

  useEffect(() => {
    getSites(setSitesWrapper, [id]);
    getPosts(setPosts, [id]);
  }, [id]);

  if (!site.Name || posts.length === 0) {
    return renderLoader();
  }

  return (
    <div className="mt-8 max-w-lg sm:mx-auto md:max-w-none">
      <div className="flex flex-1 items-center justify-center font-mono px-12 gap-x-8 mb-6">
        <img alt={site.Name} className="h-24" src={site.Icon} />
        <div className="text-left">
          <p className="font-mono text-lg">{site.Name}</p>
          <p className="text-md">{site.Authors.length > 1 ? 'Authors: ' : 'Author: '}{site.Authors.join(', ')} </p>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-y-8 md:grid-cols-3 lg:grid-cols-4 md:gap-x-12 md:gap-y-6 px-12">
        {posts.map((post) => (
          Post({...post})
        ))}
      </div>
    </div>
  )
}

function renderLoader() {
  return (
    <div className='content-center flex items-center justify-center mt-6'>
        <LoadingIcon />
      </div>
  )
}

export default SitePage