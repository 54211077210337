export const getSites = (callback, sites = []) => {
  let sitesUrl = `${process.env.REACT_APP_API_URL}/sites?`

  for (let i in sites) {
    sitesUrl += `siteId=${sites[i]}&`;
  }

  console.log(`fetching sites from ${sitesUrl}`);

  fetch(sitesUrl ,
    {
      headers: {
        'Accept': 'application/json'      
      }
    }
  ).then((response) => {
    return response.json();
  }).then((json) => {
    //console.log(`sites: ${JSON.stringify(json.sites)}`);
  
    return json.sites;
  }).then(callback);
};

export const getPosts = (callback, siteId) => {
  let postsUrl = `${process.env.REACT_APP_API_URL}/posts/`;
  
  if (siteId) {
    postsUrl += `${siteId}/`;
  }

  console.log(`fetching posts from ${postsUrl}`);
  
  fetch(postsUrl,
    {
      headers: {
        'Accept': 'application/json'      
      }
    }
  ).then((response) => {
    return response.json();
  }).then((json) => {
    return json.posts;
  }).then(callback);
};

export const getLatestPosts = () => {
  let postsUrl = `${process.env.REACT_APP_API_URL}/posts/latest`;

  console.log(`fetching posts from ${postsUrl}`);
  
  return fetch(postsUrl,
    {
      headers: {
        'Accept': 'application/json'      
      }
    }
  ).then((response) => {
    return response.json();
  }).then((json) => {
    return json.posts;
  })
}

export async function getLatestAsync() {
  let postsUrl = `${process.env.REACT_APP_API_URL}/posts/latest`;
  
  return await fetch(postsUrl,
    {
      headers: {
        'Accept': 'application/json'      
      }
    }
  ).then((response) => {
    return response.json();
  }).then((json) => {
    return json.posts;
  });
}