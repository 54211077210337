import {React, Component} from 'react';
import {Disclosure} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import {Link} from 'react-router-dom';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      navigation: [
        { name: 'Latest', href: '/', current: false },
        { name: 'Sites', href: '/sites', current: false },
        { name: 'About', href: '/about', current: false },
      ]
    }
  }

  updateSelected(key) {
    const n = this.state.navigation.map(e => {
      e.current = e.name === key;
      return e;
    });

    this.setState({
      navigation: n
    })
  }

  render() {
    return (
      <Disclosure as="nav" className="bg-header">
        {({open}) => (
          <>
            <div className="max-w-7xl px-6 lg:px-8">
              <div className="relative flex h-14 items-center justify-center">
                <div className="absolute left-28 sm:static sm:left-0 sm:border-r sm:border-gray-300">
                  <p className="font-mono font-extrabold text-xl text-gray-200 sm:pr-10">
                    WebComic Bin
                  </p>
                </div>
                {/* */}
                <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-200 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>

                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {this.state.navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current ? 'bg-header-highlight text-white text-md' : 'text-gray-200 hover:bg-gray-700 hover:text-white',
                            'px-3 py-2 rounded-md text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                          onClick={this.updateSelected.bind(this, item.name)}>
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pt-2 pb-3">
                {this.state.navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block px-3 py-2 rounded-md text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>

          </>
        )}
      </Disclosure>
    );
  }
}

export default Header;