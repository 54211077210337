import React from "react";
import { Link } from 'react-router-dom';

function Site(props) {
  return (
    <div key={props.SiteID} className="group relative lg:mx-10">
      <div className="rounded-md bg-gray-200 group-hover:opacity-75 aspect-none md:h-32">
        <img
          src={props.Icon}
          alt={props.Name}
          className="h-full w-full md:h-32 object-center"
        />
      </div>
      <div className="mt-1 px-2 flex justify-center">
        <div>
          <h3 className="text-sm font-bold text-gray-900">
            <Link to={`/site/${props.SiteID}`}>
              <span aria-hidden="true" className="absolute inset-0" />
              {props.Name}
            </Link>
          </h3>
        </div>
      </div>
      <div className="px-2 flex justify-center">
        <p className="text-md">{props.Authors.length > 1 ? 'Authors: ' : 'Author: '}{props.Authors.join(', ')} </p>
      </div>
      <div className="px-2 flex justify-center">
        <p className="text-md">{props.Description}</p>
      </div>
    </div>
  )
}

export default Site;