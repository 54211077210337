import React, { useState, useEffect } from 'react';
import Post from '../components/Post';
import { getLatestAsync, getLatestPosts } from '../data/fetchers';
import LoadingIcon from '../components/LoadingIcon';

function Home() {

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    getLatestAsync().then(posts => setPosts(posts));
  }, []);

  if (posts.length === 0) {
    return renderLoader()
  }

  return (
    <div className="mt-8 max-w-lg sm:mx-auto md:max-w-none px-12">
      <div className="font-mono md:text-lg lg:text-xl pb-6">
        The most recent posts this week
      </div>
      <div className="grid grid-cols-1 gap-y-8 md:grid-cols-3 lg:grid-cols-4 md:gap-x-8 md:gap-y-6">
        {posts.map((post) => (
          Post({...post})
        ))}
      </div>
    </div>
  )
}

function renderLoader() {
  return (
    <div className='content-center flex items-center justify-center mt-6'>
        <LoadingIcon />
      </div>
  )
}

export default Home