import React, {useEffect, useState} from "react";
import Site from "../components/Site";
import { getSites } from "../data/fetchers";
import LoadingIcon from '../components/LoadingIcon';

function Explore() {
  const [sites, setSites] = useState([]);

  useEffect(() => {
    getSites(setSites)
  }, []);

  if (sites.length === 0) {
    return renderLoader();
  }

  return (
    <div className="mt-8 max-w-lg sm:mx-auto md:max-w-none">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-8 md:gap-x-8 md:gap-y-6 px-12">
        {sites.map((site) => (
          Site({...site})
        ))}
      </div>
    </div>
  )
}

function renderLoader() {
  return (
    <div className='content-center flex items-center justify-center mt-6'>
        <LoadingIcon />
      </div>
  )
}

export default Explore