import React from "react";

function About() {
  return (
    <div className="mt-20 max-w-lg sm:mx-auto md:max-w-none">
     This is the about page
    </div>
  )
}

export default About