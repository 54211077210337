import React from "react";


function Post(props) {

  let imageUrl = props.imageUrl;
  if (props.ThumbnailURL) {
    imageUrl = "https://webcomic-bin-thumbnails.s3.us-west-2.amazonaws.com/" + props.ThumbnailURL;
  }

  return (
    <div key={props.PostID} className="group relative">
      <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:aspect-none lg:h-32">
        <img
          src={imageUrl}
          alt={props.Title}
          className="h-full w-full object-cover object-center lg:h-full lg:w-full"
        />
      </div>
      <div className="mt-1 px-2 flex justify-between">
        <div>
          <h3 className="text-sm font-bold text-gray-900">
            <a href={props.PostURL} target="_blank" rel="noreferrer">
              <span aria-hidden="true" className="absolute inset-0" />
              {props.Title}
            </a>
          </h3>
        </div>
        <p className="text-sm font-medium text-gray-700">{props.SiteID}</p>
      </div>
      <div className="px-2 text-right">
        <p className="text-xs font-medium text-gray-500">{props.PublishDate}</p> 
      </div>
    </div>
  )
}

export default Post;